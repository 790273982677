import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setInitialVisit, setLanguage } from '../store/actions/config';

import { LandingPCLayout } from '../layouts';
import { api } from '../services';

const LandingPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const { fetchLandingPageComponentsData } = api();

  const changeLang = lang => dispatch(setLanguage(lang));
  const changeFlagVisit = () => dispatch(setInitialVisit());

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        const response = await fetchLandingPageComponentsData(currentLang);
        if (!ignore) {
          console.debug('ignore', ignore);
          setData(response);
        }
        console.debug('response', response);
        if (!currentLang) {
          changeFlagVisit();
        }
        changeLang(response.languages[0].abbr);
      } catch (e) {
        setError(e.message);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang]);

  return <LandingPCLayout data={data} loading={loading} error={error} />;
};

export default LandingPage;
